import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'


const NavMenu = () => {
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                <NavbarBrand tag={Link} to="/">
                    <img src="/img/FR-logo-white.png" alt="logo" className="navbar-logo" />
                </NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow nav-center">
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={`nav-link ${location.pathname === '/merch' ? 'nav-active' : 'text-muted'}`}
                                to="/merch">MRCH</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={`nav-link ${location.pathname === '/videos' ? 'nav-active' : 'text-muted'}`}
                                to="/videos">VDS</NavLink>
                        </NavItem>
                        <NavItem>
                            <a
                                href="https://www.facebook.com/bandfractality"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faFacebook} size="2x" style={{ color: "#b0b0b0", padding: "0.5rem" }} />
                            </a>
                        </NavItem>
                        <NavItem>
                            <a
                                href="https://www.instagram.com/fractality_band"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: "#b0b0b0", padding: "0.5rem" }} />
                            </a>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
};

export default NavMenu;


