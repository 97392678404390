import Videos from "./components/Videos";
import Home from "./components/Home";
import Teaser from "./components/Teaser";
import Merch from "./components/Merch";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/merch',
    element: <Merch />
  },
  {
    path: '/videos',
    element: <Videos />
  },
  {
    path: '/teaser',
    element: <Teaser />
  }
];

export default AppRoutes;
