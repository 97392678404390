//import React, { useState } from 'react';
//import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
//import './Merch.css';

//const Item = ({ name, price, image, description }) => {
//    const [modalOpen, setModalOpen] = useState(false);

//    const toggleModal = () => {
//        setModalOpen(!modalOpen);
//    };

//    return (
//        <div className="item">
//            <div className="item-container" onClick={toggleModal}>
//                {/* Clickable image to open the modal */}
//                <img src={image} alt={name} className="item-image" />
//            </div>
//            <h3 className="mt-2">{name}</h3>
//            <p>Price: {price}€</p>

//            {/* Modal for item details */}
//            <Modal isOpen={modalOpen} toggle={toggleModal}>
//                <ModalHeader toggle={toggleModal}>{name}</ModalHeader>
//                <ModalBody>
//                    <img src={image} alt={name} className="modal-image" />
//                    <p>Price: {price}€</p>
//                    <p>{description}</p>
//                    {/* Add more details here */}
//                </ModalBody>
//                <ModalFooter>
//                    <Button color="secondary">
//                        Buy
//                    </Button>
//                </ModalFooter>
//            </Modal>
//        </div>
//    );
//};

//export default Item;

import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './Merch.css';
import { Link } from 'react-router-dom';

const Item = ({ name, price, images = [], description }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const nextImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setSelectedImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="item">
            <div className="item-container" onClick={toggleModal}>
                <img src={images[0]} alt={name} className="item-image" />
            </div>
            <h3 className="mt-3">{name}</h3>
            <p>Price: {price}€</p>

            {/* Modal for item details */}
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>{name}</ModalHeader>
                <ModalBody>
                    {/* Gallery of images */}
                    <div className="image-gallery">
                        <img
                            src={images[selectedImageIndex]}
                            alt={name}
                            className="modal-image"
                        />
                        {images.length > 1 && (<div className="gallery-controls">
                            <Button color="secondary" onClick={prevImage}>Previous</Button>
                            <Button color="secondary" onClick={nextImage}>Next</Button>
                        </div>)}
                    </div>
                    <p className="mt-3">Price: {price}€</p>
                    <p>{description}</p>
                    {/* Add more details here */}
                </ModalBody>
                {/*<ModalFooter>*/}
                {/*    <Link to="https://fractalityband.bandcamp.com/">*/}
                {/*    <Button color="secondary">*/}
                {/*        Buy*/}
                {/*        </Button>*/}
                {/*    </Link>*/}
                {/*</ModalFooter>*/}
            </Modal>
        </div>
    );
};

export default Item;
