import React from 'react';
import './FullPageVideo.css';


const FullPageVideo = ({ videoSource }) => {
    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    return (
        <div className="full-page-video">
            <video
                autoPlay
                muted
                loop
                className="video-background"
                onContextMenu={handleContextMenu}
                poster="/img/WebP/0925.png"            >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default FullPageVideo;
