import React from 'react';
import YouTube from 'react-youtube';
import './Videos.css';

const VideoPage = () => {
    const videoIAm = 'WU9rG4QTypE';
    const videoFreak = 'cVv4JnKj1bY';

    return (
        <div className="videos-page">
            {/*<div className="video-container">*/}
            {/*    <YouTube videoId={videoFreak} />*/}
            {/*</div>*/}
            <div className="video-container">
                <div className="video-wrapper">
                <YouTube videoId={videoIAm} />
            </div>
            </div>
        </div>
    );
};

export default VideoPage;
