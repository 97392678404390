import React from 'react';
import FullPageVideo from './FullPageVideo';
import VideoContainer from './VideoContainer';

const TeaserPage = () => {
    return (
        <div>
            {/*<FullPageVideo videoSource="/videos/0927.mp4" />*/}
            {/*<div>*/}
            {/*    <VideoContainer videoSource="/videos/1004.mp4" />*/}
            {/*</div>*/}
        </div>
    );
};

export default TeaserPage;
