import React, { useRef } from 'react';
import './Home.css';
import YouTube from 'react-youtube';


const HomePage = () => {
    //const logoRef = useRef(null);

    //const getRandomDuration = () => {
    //    // Generate a random duration between 0.1s and 2s for "on" and "off" states
    //    return `${Math.random() * 1.9 + 1.5}s`;
    //};

    //useEffect(() => {
    //    const logo = logoRef.current;

    //    const setRandomDurations = () => {
    //        const onDuration = getRandomDuration();
    //        const offDuration = getRandomDuration();
    //        logo.style.animation = `flickerAnimation ${onDuration} ease-in-out ${offDuration} alternate infinite`;
    //    };

    //    setRandomDurations();

    //    // Reapply random durations when the animation iteration ends
    //    logo.addEventListener('animationiteration', setRandomDurations);

    //    return () => {
    //        // Clean up the event listener when the component unmounts
    //        logo.removeEventListener('animationiteration', setRandomDurations);
    //    };
    //}, []);

    return (
        <div className="front-page">
            {/*<div className="logo-container">*/}
            {/*    <img*/}
            {/*        src="/img/WebP/FR-logo-white.webp"*/}
            {/*        alt="Logo"*/}
            {/*        className="logo flicker"*/}
            {/*        ref={logoRef}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="video-container">
                <iframe width="457" height="813" src="https://www.youtube.com/embed/-SOQO2Ldc_o" title="FRACTALITY // I Am (teaser)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    );
};

export default HomePage;
