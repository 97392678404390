import React from 'react';
import Item from './Item';
import './Merch.css';

const MerchPage = () => {
    const items = [
        {
            name: 'Bag',
            price: 25,
            images: ['/img/WebP/Bag.webp'],
            description: 'Sturdy cloth grocery/handbag',
        },
        {
            name: 'Hat',
            price: 15,
            images: ['/img/WebP/Hat1.webp', '/img/WebP/Hat2.webp'],
            description: 'Cool hat for your head',
        },
        {
            name: 'T-Shirt',
            price: 25,
            images: ['/img/WebP/Shirt.webp'],
            description: 'Dark shirt for your dark nipples',
        },
        {
            name: 'T-Shirt V-neck',
            price: 25,
            images: ['/img/WebP/ShirtV.webp'],
            description: 'When you\'re really proud of your chest-pubes',
        },
    ];

    return (
        <div className="merch-page">
            <h2>FRCTLTY MRCH</h2>
            <div className="item-list">
                {items.map((item, index) => (
                    <Item key={index} {...item} />
                ))}
            </div>
            <div className="centered-text">
                <h3>
                    {/*View all merch <a href="https://fractalityband.bandcamp.com/">here</a>*/}
                    Currently accepting orders through email: band.fractality@gmail.com
                </h3>
            </div>
        </div>
    );
};

export default MerchPage;
